<template>
  <section>
    <div class="my-5 text-center">
      <h1>Withdrawal Settings</h1>
    </div>
    <DynamicSettingsFor type="withdrawal" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DynamicSettingsFor from "@/views/settings/constituent/DynamicSettingsFor.vue";

export default defineComponent({
  name: "CoinsSettings",
  components: {
    DynamicSettingsFor,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
